import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "../constants/richTextOptions"

const PurchaseRules = () => {
  const SITE_NAME = "Regulamin zakupów"

  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    SITE_NAME,
  ]

  const data = useStaticQuery(graphql`
    query PurchaseRules {
      allContentfulRegulaminSklepu(limit: 1) {
        nodes {
          content {
            raw
          }
        }
      }
    }
  `)

  const text = data.allContentfulRegulaminSklepu.nodes[0].content

  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle={SITE_NAME}>
      <Hr mb={4} mt={6} />
      <Font42 mb={4}>{SITE_NAME}</Font42>
      {renderRichText(text, options)}
    </Layout>
  )
}

export default PurchaseRules
